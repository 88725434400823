import Vue from 'vue'
import router from '@/router/index'
import { store } from '@/state/store'

export default async function (component) {
  const MenuDataMapper = function (row, rowProps, handleAction) {
    return [
      {
        icon: () => 'remove_red_eye',
        viewBox: '0 0 24 24',
        title: () => 'View Details',
        click: (() => async () => {
          const component = () => import('./ReviewDetailSidebar.vue')
          store.dispatch('app/openSidebarDialog', {
            data: {
              rowData: row,
              title: `${row.managedId} Review Details`,
            },
            component,
          })
        })(),
      },
      {
        icon: () => 'edit',
        viewBox: '0 0 24 24',
        title: () => `${row.isActive ? 'Disable' : 'Enable'} Review`,
        click: (() => async () => {
          const component = () => import('./SetReviewActiveSidebar.vue')
          store.dispatch('app/openSidebarDialog', {
            data: {
              rowData: row,
              title: `${row.isActive ? 'Disable' : 'Enable'} Review`,
            },
            component,
          })
        })(),
      },
    ]
  }

  return Vue.component('WrappedActions', {
    props: {
      row: {
        type: Object,
        default: () => ({}),
      },
      handleAction: {
        type: Function,
        default: () => null,
      },
      rowProps: {
        type: Object,
        default: () => ({}),
      },
    },
    render(createElement) {
      return createElement(component, {
        props: {
          row: this.row,
          handleAction: this.handleAction,
          rowProps: this.rowProps,
          menu: new MenuDataMapper(this.row, this.rowProps, this.handleAction),
        },
      })
    },
  })
}
