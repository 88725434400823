<template>
  <v-container style="padding-top: 30px">
    <v-layout row wrap class="ratings-filters">
      <div style="margin-right: 20px; max-width: 200px">
        <CRInput
          id="ratings-from-date-input"
          v-model="fromDate"
          label="From Date"
          background-color="white"
          type="date"
          :clearable="true"
          clear-icon="replay"
        />
      </div>
      <div style="margin-right: 20px; max-width: 200px">
        <CRInput
          id="ratings-to-date-input"
          v-model="toDate"
          label="To Date"
          background-color="white"
          type="date"
          :clearable="true"
          clear-icon="replay"
        />
      </div>
      <div style="margin-right: 20px; max-width: 200px">
        <CRSelect
          id="ratings-market-select"
          v-model="marketId"
          label="Market Location"
          background-color="white"
          :items="marketOptions"
          item-value="marketId"
          item-text="marketName"
          placeholder="Select Market"
          :clearable="true"
          clear-icon="replay"
        />
      </div>
      <div style="margin-right: 20px; max-width: 200px">
        <CRSelect
          id="ratings-partner-select"
          v-model="partnerTypeId"
          label="Partner Type"
          background-color="white"
          :items="partnerTypeOptions"
          item-value="id"
          item-text="label"
          placeholder="Select Partner Type"
          :clearable="true"
          clear-icon="replay"
        />
      </div>
      <div style="max-width: 200px">
        <CRInput
          id="rating-affiliate-search"
          v-model="companySelector"
          label="Affiliate"
          background-color="white"
          type="autocomplete"
          :items="affiliateOptions"
          :search-input.sync="companySearch"
          item-text="name"
          item-value="companyId"
          flat
          solo
          hide-no-data
          hide-selected
          placeholder="Search Affiliates"
          append-icon="null"
          return-object
          :clearable="true"
          clear-icon="replay"
          @click:clear="(evt) => clearAffiliateSearch()"
          @change="onAffiliateSearchChange"
        />
      </div>
    </v-layout>
    <v-layout row wrap space-between style="margin-bottom: 25px">
      <div
        v-for="rating of ratings"
        :key="`${rating.title}-rating-score-card`"
        style="margin-right: 20px"
      >
        <PercentageSatisfactionCard
          v-if="rating.isPercentage"
          :title="rating.title"
          :percentage="rating.value"
          :number-of-responses="rating.numRatings"
        />
        <RatingScoreCard
          v-else
          :title="rating.title"
          :value="rating.value"
          :total-value="5"
          :number-of-ratings="rating.numRatings"
          :show-stars="true"
        />
      </div>
    </v-layout>
    <v-layout column sheet>
      <h1>Trip Ratings</h1>
      <DataTable v-bind="tableProps" />
    </v-layout>
  </v-container>
</template>

<script>
import DataTable from '@/components/DataTable.vue'
import MultiRatingFilter from '@/components/MultiRatingFilter.vue'
import PickupPositiveFilter from '@/components/PickupPositiveFilter.vue'
import PercentageSatisfactionCard from '@/components/PercentageSatisfactionCard.vue'
import RatingScoreCard from '@/components/RatingScoreCard.vue'
import ReservationActionableColumn from '@/components/ReservationActionableColumn.vue'
import ReviewRatingsActions from '@/components/ReviewRatingsActions.vue'
import reviewRatingsActionsWrapper from '@/components/ReviewRatingsActionsWrapper'
import affiliates from '@/services/affiliates'
import markets from '@/services/markets'
import reviews from '@/services/reviews'

import { calculatedValues } from '@/utils/predefined'
import { EventBus } from '@/utils/event-bus'
import { DateTime } from 'luxon'
import { round } from '@/utils/round'
import { filter } from '@/utils/filter'
import { sort } from '@/utils/sort'

export default {
  components: {
    DataTable,
    PercentageSatisfactionCard,
    RatingScoreCard,
  },
  metaInfo() {
    return {
      title: 'Ratings',
    }
  },
  data() {
    return {
      affiliateOptions: [],
      companySearch: null,
      companySelector: null,
      debounce: undefined,
      filters: () => [],
      fromDate: null,
      isAdmin: false,
      itemsPerPage: 10,
      latestRequestId: null,
      marketId: null,
      marketOptions: [],
      ratings: [],
      page: 1,
      partnerTypeId: null,
      partnerTypeOptions: [],
      sorts: () => [],
      totalValue: 5,
      toDate: null,
      uiFilters: [],
      tableProps: {
        addFilter: this.addFilter,
        addNewEnabled: false,
        calculatedValues,
        changePage: this.changePage,
        columns: [],
        currentPage: 1,
        detailKeyId: 'reservationReviewId',
        enableExport: false,
        enableColumnConfig: false,
        enableSavedViews: false,
        isAdmin: this.isAdmin,
        isDetailed: false,
        list: [],
        initialFilters: [],
        loading: true,
        perPage: 10,
        tableId: 'reservation_ratings_table_view',
        total: 0,
        removeFilter: this.removeFilter,
        setSort: this.setSort,
        sort: this.sort,
        shareFilters: this.receiveFilters,
        shareSorts: this.receiveSorts,
      },
    }
  },
  computed: {
    computedFromDate() {
      return this.fromDate && this.fromDate.length > 0
        ? this.fromDate
        : '2018-07-01'
    },
    computedToDate() {
      return this.toDate || new Date().toISOString()
    },
  },
  watch: {
    async affiliateSearchTerm(value) {
      await this.searchAffiliates(value)
    },
    async companySelector() {
      await this.getAverages()
      this.refresh()
    },
    async fromDate() {
      await this.getAverages()
      this.refresh()
    },
    async toDate() {
      await this.getAverages()
      this.refresh()
    },
    async marketId() {
      await this.getAverages()
      this.refresh()
    },
    async partnerTypeId() {
      await this.getAverages()
      this.refresh()
    },
    companySearch() {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      if (!this.immediate) {
        this.debounce = setTimeout(() => {
          this.immediate = true
          this.searchAffiliates()
        }, 500)
        return
      }
      this.searchAffiliates()
    },
  },
  async mounted() {
    await this.getColumns()
    await this.getAverages()
    await this.getMarkets()
    await this.getPartnerTypes()
    this.refresh()

    EventBus.$on('refresh-reviews', () => {
      this.getAverages()
      this.refresh()
    })
  },
  methods: {
    async getColumns() {
      const reviewRatingsWrappedActions = await reviewRatingsActionsWrapper(
        ReviewRatingsActions
      )

      this.tableProps.columns = [
        {
          _t_id: '367f165c-7937-4930-8356-2a13b6ddf090',
          prop: '/',
          text: 'Actions',
          type: 'actions',
          component: reviewRatingsWrappedActions,
          sort: false,
          filter: false,
          detail: false,
          shrinkWidth: true,
        },
        {
          _t_id: 'c11a2c18-c337-11ed-afa1-0242ac120002',
          prop: 'reservationId',
          component: ReservationActionableColumn,
          text: 'Res ID',
          type: 'text',
          sort: true,
          filter: true,
          detail: false,
          filterType: 'eq',
          childMethod: 'and',
          sortProp: 'reservationId',
          filterProp: 'reservation/managedId',
          displayType: 'clickable-link-new',
        },
        {
          _t_id: '1cfd886c-a822-4e62-a267-2777b2e786b1',
          prop: ['customer/firstName', 'customer/lastName'],
          text: 'Customer',
          type: 'text',
          sort: true,
          filter: true,
          detail: false,
          sortProp: 'customer/firstName',
          filterType: 'contains',
        },
        {
          _t_id: '6f21e10f-2092-49df-b5d5-4a19d40b3840',
          prop: 'referralCompany/name',
          text: 'Company',
          type: 'text',
          sort: true,
          filter: false,
          detail: false,
        },
        {
          _t_id: 'cd11b498-b37b-42dd-922f-88d5d201e219',
          prop: 'charterupRating',
          text: 'CharterUP',
          type: 'number',
          sort: true,
          filter: true,
          detail: false,
          sortProp: 'charterupRating',
          filterType: 'eq',
          childMethod: 'and',
          predefined: [
            {
              text: 'Select Ratings',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              controls: [
                {
                  id: '6ee847cc-c2a1-11ed-afa1-0242ac120002',
                  text: 'Select Ratings',
                  filterType: 'contains',
                  component: MultiRatingFilter,
                },
              ],
            },
          ],
        },
        {
          _t_id: '16d9a3b5-6ed1-4cdc-838c-fc5a1b3d958d',
          prop: 'operatorRating',
          text: 'Operator',
          type: 'number',
          sort: true,
          filter: true,
          detail: false,
          sortProp: 'operatorRating',
          filterType: 'eq',
          childMethod: 'and',
          predefined: [
            {
              text: 'Select Ratings',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              controls: [
                {
                  id: '6ad9115e-c2a4-11ed-afa1-0242ac120002',
                  text: 'Select Ratings',
                  filterType: 'contains',
                  component: MultiRatingFilter,
                },
              ],
            },
          ],
        },
        {
          _t_id: '4be9e559-400d-4aa1-8f72-eb9fcbdc7de7',
          prop: 'driverRating',
          text: 'Driver',
          type: 'text',
          sort: true,
          filter: true,
          detail: false,
          sortProp: 'driverRating',
          filterType: 'eq',
          childMethod: 'and',
          predefined: [
            {
              text: 'Select Ratings',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              controls: [
                {
                  id: 'e8248592-c2a6-11ed-afa1-0242ac120002',
                  text: 'Select Ratings',
                  filterType: 'contains',
                  component: MultiRatingFilter,
                },
              ],
            },
          ],
        },
        {
          _t_id: 'f7ac859d-c4f3-4dbb-b892-671f0b0ef716',
          prop: 'vehicleRating',
          text: 'Vehicle',
          type: 'text',
          sort: true,
          filter: true,
          detail: false,
          sortProp: 'vehicleRating',
          filterType: 'eq',
          childMethod: 'and',
          predefined: [
            {
              text: 'Select Ratings',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              controls: [
                {
                  id: 'e4ca30b8-c2a6-11ed-afa1-0242ac120002',
                  text: 'Select Ratings',
                  filterType: 'contains',
                  component: MultiRatingFilter,
                },
              ],
            },
          ],
        },
        {
          _t_id: 'a3edbb64-1f36-4c99-8a9a-6e9bdc4c9601',
          prop: 'pickupPositive',
          text: 'Pickup',
          computedText: (item) => {
            if (item == null) return ''
            else return item ? 'Good' : 'Bad'
          },
          type: 'text',
          sort: true,
          filter: true,
          detail: false,
          sortProp: 'pickupPositive',
          filterType: 'eq',
          childMethod: 'and',
          predefined: [
            {
              text: 'Select Pickup Satisfaction',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              controls: [
                {
                  id: 'df3963a8-c2a6-11ed-afa1-0242ac120002',
                  text: 'Select Pickup Satisfaction',
                  filterType: 'contains',
                  component: PickupPositiveFilter,
                },
              ],
            },
          ],
        },
        {
          _t_id: '691215b6-dd7a-4e51-abd5-f11739fb374c',
          text: 'Average',
          computedText: (item, row) => {
            let numRatings = 0
            let totalRating = 0
            for (let rating of [
              row.charterupRating,
              row.operatorRating,
              row.driverRating,
              row.vehicleRating,
            ]) {
              if (rating) {
                numRatings += 1
                totalRating += rating
              }
            }

            if (numRatings == 4) {
              return round(totalRating / numRatings, 2)
            }
            return ''
          },
          type: 'text',
          sort: false,
          filter: false,
          detail: false,
        },
        {
          _t_id: '7065653d-4453-46ae-8ce6-d0022d3be5f3',
          text: 'Review Disabled',
          computedText: (item, row) => (row.isActive ? 'Enabled' : 'Disabled'),
          type: 'text',
          sort: true,
          sortProp: 'isActive',
          filter: false,
          detail: false,
        },
      ]
    },
    async getAverages() {
      const fromDateFormatted = DateTime.fromISO(
        this.computedFromDate
      ).toFormat('yyyy-MM-dd HH:mm:ss')
      const toDateFormatted = DateTime.fromISO(this.computedToDate).toFormat(
        'yyyy-MM-dd HH:mm:ss'
      )

      const params = {
        fromDate: fromDateFormatted,
        toDate: toDateFormatted,
        marketId: this.marketId,
        partnerTypeId: this.partnerTypeId,
        referralCompanyId: this.companySelector?.companyId,
      }
      const ratingsAverages = await reviews
        .getAverages(params)
        .then((data) => data.data?.reviewAverages)

      this.ratings = [
        {
          title: 'CharterUP',
          value: round(ratingsAverages.charterUPRating, 1),
          numRatings: ratingsAverages.charterUPRatingCount,
        },
        {
          title: 'Operator',
          value: round(ratingsAverages.operatorRating, 1),
          numRatings: ratingsAverages.operatorRatingCount,
        },
        {
          title: 'Driver',
          value: round(ratingsAverages.driverRating, 1),
          numRatings: ratingsAverages.driverRatingCount,
        },
        {
          title: 'Vehicle',
          value: round(ratingsAverages.vehicleRating, 1),
          numRatings: ratingsAverages.vehicleRatingCount,
        },
        {
          title: 'Pickup',
          value: round(ratingsAverages.pickupSatisfaction * 100, 1),
          numRatings: ratingsAverages.pickupSatisfactionCount,
          isPercentage: true,
        },
      ]
    },
    async getMarkets() {
      const { resultList } = await markets
        .tableView({ pageSize: -1 })
        .then((data) => data.data)
      this.marketOptions = resultList
    },
    async getPartnerTypes() {
      const partners = await this.$store
        .dispatch('types/getPartnerTypes', { pageSize: -1 })
        .then((data) => data.data)
      this.partnerTypeOptions = partners.resultList
    },
    async setUIFilters() {
      const nonNullRatingsFilter = [
        {
          column: {
            _t_id: 'b695da68-bf8f-11ed-afa1-0242ac120002',
            filterProp: 'charterupRating',
            type: 'text',
            method: 'or',
            filterType: 'isnotnull',
          },
          value: 1,
        },
        {
          column: {
            _t_id: 'b8f9c9e0-bf8f-11ed-afa1-0242ac120002',
            filterProp: 'operatorRating',
            type: 'text',
            method: 'or',
            filterType: 'isnotnull',
          },
          value: 1,
        },
        {
          column: {
            _t_id: 'bb45eab2-bf8f-11ed-afa1-0242ac120002',
            filterProp: 'driverRating',
            type: 'text',
            method: 'or',
            filterType: 'isnotnull',
          },
          value: 1,
        },
        {
          column: {
            _t_id: 'bdc37034-bf8f-11ed-afa1-0242ac120002',
            filterProp: 'vehicleRating',
            type: 'text',
            method: 'or',
            filterType: 'isnotnull',
          },
          value: 1,
        },
        {
          column: {
            _t_id: 'c0b0e93e-bf8f-11ed-afa1-0242ac120002',
            filterProp: 'pickupPositive',
            type: 'text',
            method: 'or',
            filterType: 'isnotnull',
          },
          value: 1,
        },
      ]

      const createdOnFromDateFilter = {
        column: {
          _t_id: 'b25019d5-7e63-483a-9d1b-570803a72d2e',
          filterProp: 'createdOn',
          type: 'date',
          method: 'and',
          filterType: 'gte',
        },
        value: this.computedFromDate,
      }

      const createdOnToDateFilter = {
        column: {
          _t_id: '7030a9e4-fad4-4f84-9863-2861e88c927d',
          filterProp: 'createdOn',
          type: 'date',
          method: 'and',
          filterType: 'lte',
        },
        value: this.computedToDate,
      }

      const marketFilter = {
        column: {
          _t_id: '2e7b2792-bf84-11ed-afa1-0242ac120002',
          filterProp: 'reservation/trip/pricingMarket/marketId',
          type: 'text',
          method: 'and',
          filterType: 'eq',
        },
        value: this.marketId,
      }

      const partnerTypeFilter = {
        column: {
          _t_id: '958388b2-bf84-11ed-afa1-0242ac120002',
          filterProp: 'referralCompany/partnerTypeId',
          type: 'text',
          method: 'and',
          filterType: 'eq',
        },
        value: this.partnerTypeId,
      }

      const companyFilter = {
        column: {
          _t_id: 'ae86c9dc-bf84-11ed-afa1-0242ac120002',
          filterProp: 'referralCompany/companyId',
          type: 'text',
          method: 'and',
          filterType: 'eq',
        },
        value: this.companySelector?.companyId,
      }

      for (let filter of this.uiFilters) {
        this.filters.remove(filter)
      }

      const nonNullRatingsParentFilter = this.filters.createParent('or')
      await nonNullRatingsFilter.forEach((filter) => {
        this.filters.add(nonNullRatingsParentFilter, filter)
        this.uiFilters.push(filter)
        this.uiFilters.push(nonNullRatingsParentFilter)
      })

      for (let filter of [
        nonNullRatingsParentFilter,
        createdOnFromDateFilter,
        createdOnToDateFilter,
        marketFilter,
        partnerTypeFilter,
        companyFilter,
      ]) {
        let parentFilter = this.filters.createParent('and')
        await this.filters.add(parentFilter, filter)
        this.uiFilters.push(filter)
        this.uiFilters.push(parentFilter)
      }
    },
    async getTable() {
      await this.setUIFilters()

      const sorts = this.sorts.asQueryParams()
      const filters = this.filters.asQueryParams()
      const params = {
        sorts,
        filters,
        pageSize: this.itemsPerPage,
        page: this.currentPage,
      }

      this.tableProps.loading = true
      const ratingsResponse = await reviews.getReviews(params)

      const ratings = ratingsResponse.data
      this.tableProps.perPage = this.itemsPerPage
      this.tableProps.currentPage = this.currentPage
      this.tableProps.list = ratings.resultList
      this.tableProps.total = ratings.count
      this.tableProps.loading = false
    },
    async searchAffiliates() {
      this.immediate = false

      const filterObjects = []
      const affilSort = sort()
      const affilFilter = filter()

      if (this.companySearch) {
        filterObjects.push({
          column: {
            _t_id: 'text_search_refer',
            prop: 'name',
            filterType: 'contains',
          },
          value: this.companySearch,
        })
      }

      affilSort.add({
        prop: 'referralCount',
        direction: 'desc',
      })

      filterObjects.forEach((fil) => {
        const parent = affilFilter.createParent('and')
        affilFilter.add(parent, fil)
      })

      const filterParams = affilFilter.asQueryParams()
      const sortParams = affilSort.asQueryParams()

      this.affiliateOptions = await affiliates
        .getAffiliates({
          sorts: sortParams,
          filters: filterParams,
          pageSize: 5,
          page: 1,
        })
        .then(({ data }) => {
          return data.resultList
        })
        .catch((e) => {})

      this.immediate = true
    },
    addFilter() {
      this.currentPage = 1
      this.refresh()
    },
    changePage(pagination) {
      this.currentPage = pagination.page
      this.itemsPerPage = pagination.rowsPerPage
      this.refresh()
    },
    clearAffiliateSearch() {
      this.companySearch = null
      this.companySelector = null
    },
    onAffiliateSearchChange() {
      this.companySearch = null
    },
    receiveFilters(filters) {
      this.filters = filters
    },
    receiveSorts(sorts) {
      this.sorts = sorts
    },
    refresh(immediate) {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      if (immediate) {
        this.getTable()
      } else {
        this.debounce = setTimeout(this.getTable, 500)
      }
    },
    removeFilter() {
      this.currentPage = 1
      this.refresh(true)
    },
    sort(sortItem) {
      this.sorts.add(sortItem)
      this.refresh(true)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-input.v-text-field--solo {
  .v-input__control {
    .v-input__slot {
      background-color: white !important;
    }
  }
}
</style>
