<template>
  <v-layout class="percentage-satisfaction-card">
    <v-layout column>
      <p class="card-title">
        {{ title }}
      </p>
      <v-layout
        v-if="numberOfResponses > 0"
        row
        class="percentage-satisfaction-row"
      >
        <v-layout col style="padding-right: 10px">
          <v-layout row align-center>
            <p class="percentage-value">
              {{ `${percentage}%` }}
            </p>
            <img src="@/assets/images/icon-satisfied.png" width="35" />
          </v-layout>
        </v-layout>
        <v-layout col>
          <v-layout row align-center>
            <p class="percentage-value">
              {{ `${100 - percentage}%` }}
            </p>
            <img src="@/assets/images/icon-unsatisfied.png" width="35" />
          </v-layout>
        </v-layout>
      </v-layout>
      <div class="number-of-responses">
        <p style="margin-bottom: 0px">
          {{ responsesText }}
        </p>
      </div>
    </v-layout>
  </v-layout>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    percentage: {
      type: Number,
      default: 0,
    },
    numberOfResponses: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    responsesText() {
      return `${
        this.numberOfResponses > 0
          ? this.numberOfResponses.toLocaleString()
          : 'No'
      } responses`
    },
  },
}
</script>

<style lang="scss" scoped>
.percentage-satisfaction-card {
  box-sizing: border-box;
  background-color: $white;
  border-radius: 10px;
  display: flex;
  height: 120px;
  min-width: 190px;
  max-width: 210px;
  padding: 17px 18px;
  position: relative;
  width: fit-content;

  .card-title {
    font-size: 15px;
    font-weight: 600;
    margin: 0px;
    padding: 0px;
  }

  .percentage-satisfaction-row {
    padding: 5px 0px;

    .percentage-value {
      color: black;
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 0px;
      margin-right: 5px;
    }
  }

  .no-responses-text {
    color: black;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 0px;
  }

  .number-of-responses {
    color: gray;
    font-size: 13px;
    font-weight: 550;
    margin-bottom: 2px;
  }
}
</style>
