<template>
  <div
    class="rating-score-card"
    style="height: 120px"
    :style="showStars ? { 'min-width': '200px' } : { 'min-width': '120px' }"
  >
    <v-layout column>
      <p class="rating-score-title">
        {{ title }}
      </p>
      <v-layout
        v-if="numberOfRatings > 0"
        align-center
        row
        class="rating-score-row"
      >
        <p class="rating-score-value">
          {{ `${formattedValue} / ${totalValue}` }}
        </p>
        <div v-if="showStars">
          <StarRating
            read-only
            :rating="value"
            :show-rating="false"
            :max-rating="5"
            :star-size="20"
            :round-start-rating="false"
            :border-width="0"
            :padding="3"
            :glow="0"
            glow-color="none"
            border-color="none"
          />
        </div>
      </v-layout>
      <div class="number-of-ratings">
        <p style="margin-bottom: 0px">
          {{ ratingsText }}
        </p>
      </div>
    </v-layout>
  </div>
</template>

<script>
import { round } from '@/utils/round'
import StarRating from 'vue-star-rating'

export default {
  components: {
    StarRating,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: Number,
      default: 0,
    },
    totalValue: {
      type: Number,
      default: 0,
    },
    showStars: {
      type: Boolean,
      default: false,
    },
    numberOfRatings: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    formattedValue() {
      return round(this.value, 1)
    },
    roundedValue() {
      const floor = Math.floor(this.value)
      if (this.value - floor < 0.5) {
        return floor
      } else {
        return floor + 0.5
      }
    },
    ratingsText() {
      return `${
        this.numberOfRatings > 0 ? this.numberOfRatings.toLocaleString() : 'No'
      } ratings`
    },
  },
}
</script>

<style lang="scss" scoped>
.rating-score-card {
  background-color: white;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  height: fit-content;
  min-height: 75px;
  max-width: 200px;
  padding: 17px 18px;
  position: relative;
  width: fit-content;

  .rating-score-title {
    font-size: 15px;
    font-weight: 600;
    margin: 0px;
    padding: 0px;
  }

  .rating-score-row {
    height: 20px;
    margin: 3px 0px 4px;

    .rating-score-value {
      color: black;
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 0px;
      margin-right: 10px;
      min-width: 45px;
    }

    .no-ratings-text {
      color: black;
      font-size: 15px;
      font-weight: bold;
    }
  }

  .number-of-ratings {
    font-size: 13px;
    font-weight: 550;
    color: gray;
    margin-bottom: 0px;
  }
}
</style>
