<template>
  <CRSelect
    :id="`${id}-multi-rating-filter-select-id`"
    ref="ratingSelector"
    v-model="ratingSelections"
    :items="ratingItems"
    chips
    class="rating-selector"
    flat
    hide-details
    item-text="label"
    item-value="id"
    multiple
    solo
    @input="setFilter"
  />
</template>

<script>
import { deepClone } from '@/utils/deepClone'
import { authComputed } from '@/state/helpers'

export default {
  props: {
    filters: {
      type: Object,
      default: () => undefined,
    },
    control: {
      type: Object,
      default: () => undefined,
    },
    column: {
      type: Object,
      default: () => undefined,
    },
    addFilter: {
      type: Function,
      default: () => () => true,
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      ratingItems: [1, 2, 3, 4, 5],
      ratingSelections: [],
      ratingFilter: {
        column: {
          _t_id: `${this.column._t_id}-26b6f2a9-ee42-4eaa-968b-8330aaf05fa8`,
          prop: this.column.prop,
          filterType: 'contains',
        },
      },
      activeRatingFilter: undefined,
    }
  },
  computed: {
    ...authComputed,
  },
  async mounted() {
    const activeRatingFilter = this.findFilter(this.ratingFilter)
    if (activeRatingFilter) {
      this.activeRatingFilter = activeRatingFilter
      this.$nextTick(() => {
        const rebuiltratingSelections = activeRatingFilter.value
        if (!!rebuiltratingSelections) {
          this.ratingSelections = rebuiltratingSelections
            .split(' ')
            .reduce((acc, selection) => {
              const num = parseInt(selection, 10)
              if (!isNaN(num)) {
                acc.push(num)
              }
              return acc
            })
          this.addFilter()
        }
      })
    }
  },
  methods: {
    findFilter(filter) {
      return filter && this.filters.find(filter)
    },
    setFilter(e) {
      if (this.findFilter(this.activeRatingFilter)) {
        this.filters.remove(this.activeRatingFilter)
      }
      if (this.ratingSelections.length) {
        const grandParent = this.filters.createParent('and')
        this.grandParentFilter = grandParent
        this.addRatingFilter()
        this.addFilter()
      }
      this.$emit('make-dirty-request')
      this.addFilter()
    },
    addRatingFilter() {
      const cloneRatingSelections = [].concat(this.ratingSelections)
      const newFilter = deepClone(this.ratingFilter)
      newFilter.hideOnFilterBar = true
      newFilter.value = cloneRatingSelections.join(' ')
      this.activeRatingFilter = newFilter
      newFilter.stepParent = this.column._t_id
      this.filters.add(this.grandParentFilter, newFilter)
    },
  },
}
</script>

<style lang="scss" scoped>
.rating-selector {
  width: 200px !important;
  color: $primary;
  background-color: $white;
  border: 1px solid $primary;
  border-radius: 4px;
}

.rating-selector::v-deep {
  .v-input__control {
    min-height: 42px;
  }

  .theme--light.v-chip {
    color: $white !important;
    background: $primary !important;
  }
}
</style>
