<template>
  <CRSelect
    :id="`${id}-multi-pickup-filter-select`"
    v-model="pickupSelections"
    :items="pickupItems"
    class="pickup-positive-selector"
    chips
    flat
    hide-details
    item-text="label"
    item-value="id"
    multiple
    solo
    @input="setFilter"
  />
</template>

<script>
import { deepClone } from '@/utils/deepClone'
import { authComputed } from '@/state/helpers'

export default {
  props: {
    filters: {
      type: Object,
      default: () => undefined,
    },
    control: {
      type: Object,
      default: () => undefined,
    },
    column: {
      type: Object,
      default: () => undefined,
    },
    addFilter: {
      type: Function,
      default: () => () => true,
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      pickupItems: [
        {
          id: 1,
          label: 'Good',
          value: true,
        },
        {
          id: 0,
          label: 'Bad',
          value: false,
        },
      ],
      pickupSelections: [],
      pickupFilter: {
        column: {
          _t_id: '90e2ce58-c41b-11ed-afa1-0242ac120002',
          prop: 'pickupPositive',
          filterType: 'eq',
        },
        activePickupFilter: undefined,
        hideOnFilterBar: true,
      },
    }
  },
  computed: {
    ...authComputed,
  },
  async mounted() {
    const activeFilter = this.findFilter(this.pickupFilter)
    if (activeFilter) {
      this.activePickupFilter = activeFilter
      this.$nextTick(() => {
        const rebuiltSelections = activeFilter.value
        if (!!rebuiltSelections) {
          this.pickupSelections = rebuiltSelections
            .split(' ')
            .reduce((acc, selection) => {
              const num = parseInt(selection)
              if (!isNaN(num)) {
                acc.push(num)
              }
              return acc
            })
          this.addFilter()
        }
      })
    }
  },
  methods: {
    findFilter(filter) {
      return filter && this.filters.find(filter)
    },
    setFilter() {
      this.filters.remove(this.pickupFilter)

      if (this.pickupSelections.length) {
        const grandParent = this.filters.createParent('and')
        this.grandParentFilter = grandParent
        this.addPickupFilter()
      }

      this.$emit('make-dirty-request')
      this.addFilter()
    },
    addPickupFilter() {
      const newFilter = deepClone(this.pickupFilter)
      newFilter.value = this.pickupSelections.join(' ')
      newFilter.stepParent = this.column?._t_id
      this.filters.add(this.grandParentFilter, newFilter)
    },
  },
}
</script>

<style lang="scss" scoped>
.pickup-positive-selector {
  width: 200px !important;
  color: $primary;
  background-color: $white;
  border: 1px solid $primary;
  border-radius: 4px;
}

.pickup-positive-selector::v-deep {
  .v-input__control {
    min-height: 42px;
  }

  .theme--light.v-chip {
    color: $white !important;
    background: $primary !important;
  }
}
</style>
